<template>
  <b-form-select
    v-bind="$attrs"
    v-on="$listeners"
  >
    <b-form-select-option :value="null" disabled>{{ displayText('staff.branch_select' ,'請選擇分店') }}</b-form-select-option>
    <b-form-select-option
      v-for="(branchOption, index) in branchOptions"
      :key="index"
      :value="branchOption.id"
    >
      {{ displayBranchName(branchOption) }}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import branchApi from "@/apis/branch";
import {mapGetters, mapState} from 'vuex';
import { displayText } from "@/utils/dashboardUtils";

export default {
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('general', [
      'myBranches',
    ]),
    branchOptions() {
      if (this.$permissions.has(this.$permissions.consts.BRANCH_TOP_VIEW)) {
        return this.allBranches
      }

      return this.branches
    },
  },
  data() {
    return {
      allBranches: [],
      branches: []
    };
  },
  mounted() {
    if (this.$permissions.has(this.$permissions.consts.BRANCH_TOP_VIEW)) {
      this.fetchAllBranches();
    }
    this.branches = this.myBranches.sort((a, b) => {
      if (a.level === b.level) {
        return a.order - b.order;
      }
      return a.level - b.level;
    })
  },
  methods: {
    displayText,
    async fetchAllBranches() {
      const { data } = await branchApi.getBranches(this.organization, { is_all: true });
      this.allBranches = data.data.sort((a, b) => {
        if (a.level === b.level) {
          return a.order - b.order;
        }
        return a.level - b.level;
      });
    },
    displayBranchName(branch) {
      if (branch.level > 1) {
        return '-'.repeat(branch.level - 1) + ` ${branch.branch_code} ${branch.name}`
      }
      return `${branch.branch_code} ${branch.name}`
    }
  },
}
</script>
