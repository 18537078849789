<template>
  <div>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      v-bind:label="formLabel"
      v-if="isShow"
    >
      <div v-if="setting && setting.type == 'input'">
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          v-model="inputValue"
          :state="isError ? false : null"
          :readonly="isReadOnly"
        ></b-form-input>
      </div>
      <div v-if="setting && setting.type == 'select'">
        <b-form-select
          v-model="inputValue"
          :state="isError ? false : null"
          :options="options"
          :disabled="isReadOnly"
          class="mr-3"
        >
        </b-form-select>
      </div>
      <div v-else>
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          v-model="inputValue"
          :state="isError ? false : null"
          :readonly="isReadOnly"
        ></b-form-input>
      </div>
      <b-form-invalid-feedback v-if="isError">
        {{ errorMessage }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    value: String, // 接收 v-model 傳入的值
    formLabel: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    isShow: {
      type: Boolean,
      required: true,
    },
    setting: {
      type: Object,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value; // 取得父元件傳入的值
      },
      set(newValue) {
        this.$emit("input", newValue); // 發送 'input' 事件回傳新值
      },
    },
    options() {
      return this.setting && Array.isArray(this.setting.options)
        ? [...this.setting.options].sort((a, b) => b.level - a.level)
        : [];
    },
  },
  mounted() {
    if (!this.inputValue && this.options && this.options.length > 0) {
      this.$nextTick(() => {
        this.$emit("input", this.options[0].value);
      });
    }
  },
};
</script>

<style></style>
